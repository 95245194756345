import React, { useEffect, useState, useCallback, useMemo } from 'react';
import json2mq from 'json2mq';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function useResponsiveQuery() {
  const [isMobile, setMobile] = useState(false);
  const matches = useMediaQuery(
    json2mq({
      minWidth: 320,
      maxWidth: 991,
    })
  );

  const mediaQueryList = useMemo(() => setMobile(matches), [matches]);
  return isMobile;
}
