import { useCallback, useEffect, useState } from 'react';
import Video, { LocalVideoTrack, LocalAudioTrack } from 'twilio-video';

export function useLocalAudioTrack() {
  const [track, setTrack] = useState<LocalAudioTrack>();

  useEffect(() => {
    Video.createLocalAudioTrack().then(newTrack => {
      setTrack(newTrack);
      console.log('fresh audio track');
    });
  }, []);

  const setLocalAudioTrack = useCallback(audioTrack => {
    console.log('new audio to be track added');
    setTrack(audioTrack);
    return new Promise(resolve => {
      resolve(track);
    });
  }, []);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return [track, setLocalAudioTrack] as const;
  //   return track;
}

export function useLocalVideoTrack() {
  const [track, setTrack] = useState<LocalVideoTrack>();
  const [deviceId, setDeviceId] = useState('');

  const getLocalVideoTrack = useCallback(() => {
    console.log(deviceId);
    return Video.createLocalVideoTrack({
      frameRate: 24,
      height: 720,
      width: 1280,
      name: 'camera',
      deviceId: deviceId,
    }).then(newTrack => {
      console.log('new video track added');
      setTrack(newTrack);
      return newTrack;
    });
  }, [deviceId]);

  const setLocalVideoTrack = useCallback((videoTrack, newDeviceId) => {
    console.log(newDeviceId);
    setTrack(videoTrack);
    setDeviceId(newDeviceId);
    return new Promise(resolve => {
      resolve(track);
    });
    // return track;
  }, []);

  useEffect(() => {
    // We get a new local video track when the app loads.
    getLocalVideoTrack();
  }, [getLocalVideoTrack]);

  useEffect(() => {
    const handleStopped = () => {
      console.log('track stopped');
      setTrack(undefined);
    };
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalVideoTrack, setLocalVideoTrack] as const;
}

export default function useLocalTracks() {
  const [audioTrack, setLocalAudioTrack] = useLocalAudioTrack();
  const [videoTrack, getLocalVideoTrack, setLocalVideoTrack] = useLocalVideoTrack();

  const localTracks = [audioTrack, videoTrack].filter(track => track !== undefined) as (
    | LocalAudioTrack
    | LocalVideoTrack
  )[];

  return { localTracks, getLocalVideoTrack, setLocalVideoTrack, setLocalAudioTrack };
}
