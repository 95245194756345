import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalParticipant, RemoteParticipant, RemoteVideoTrack, LocalVideoTrack } from 'twilio-video';

import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import MicOff from '@material-ui/icons/MicOff';
import NetworkQualityLevel from '../NewtorkQualityLevel/NetworkQualityLevel';
import ParticipantConnectionIndicator from './ParticipantConnectionIndicator/ParticipantConnectionIndicator';
import PinIcon from './PinIcon/PinIcon';
import ScreenShare from '@material-ui/icons/ScreenShare';
import VideocamOff from '@material-ui/icons/VideocamOff';

import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import usePublications from '../../hooks/usePublications/usePublications';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublicationIsTrackEnabled from '../../hooks/usePublicationIsTrackEnabled/usePublicationIsTrackEnabled';
import useResponsiveQuery from '../../hooks/useResponsiveQuery/useResponsiveQuery';
import useTrack from '../../hooks/useTrack/useTrack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'static',
      display: 'flex',
      flexDirection: 'row-reverse',
    //   paddingBottom: '10px',
      alignItems: 'center',
    //   height: '115px',
      overflow: 'hidden',
      cursor: 'pointer',
      '& video': {
        filter: 'none',
      },
      '& svg': {
        stroke: 'black',
        strokeWidth: '0.8px',
      },
      flex: '1',
      fontSize: '18px',
      color: '#171717',
      padding: '0 20px',
      '& h4': {
        fontSize: '18px',
      }
    },
    containerMobile: {
        padding: '0 10px',
    },
    isVideoSwitchedOff: {
      '& video': {
        filter: 'blur(4px) grayscale(1) brightness(0.5)',
      },
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 'auto',
      background: 'transparent',
      left: '110px',
      top: '10px'
    },
    infoContainerMobile: {
        left: '36%',
      },
    hideVideo: {
    //   background: 'black',
    },
    identity: {
        margin: 0,
        alignItems: 'center',
        fontWeight: 'normal',
        textTransform: 'capitalize',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100px',
        display: 'inline-block',
    },
    identityMobile: {
        width: '70px',
        fontSize: '15px'
    },
    infoRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    },
    muteAudioContainer: {
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'auto',
        background: 'transparent',
        left: '10px',
        bottom: '10px'
    },
    muteVideoContainer: {
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'auto',
        background: 'transparent',
        left: '100px',
        bottom: '10px'
    },
    muteVideoContainerMobile: {
        left: '36%',
    },
    screenShareContainer: {
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'auto',
        background: 'transparent',
        left: '10px',
        top: '10px'
    },
    muteMediaStyle: {
        '& > svg' :{
            fill: 'none',
            stroke: 'red',
            strokeWidth: '1px',
            strokeLinejoin: 'round'
        }
    }
  })
);

interface ParticipantInfoProps {
  participant: LocalParticipant | RemoteParticipant;
  children: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
}

export default function ParticipantInfo({ participant, onClick, isSelected, children }: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => p.kind === 'video');

  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  const isAudioEnabled = usePublicationIsTrackEnabled(audioPublication);
  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(p => p.trackName === 'screen');

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const classes = useStyles();
  const isMobile = useResponsiveQuery();
// console.log(participant);
  return (
    <div
      className={clsx(classes.container,['participant-tools'], {
        [classes.isVideoSwitchedOff]: isVideoSwitchedOff, [classes.containerMobile] : isMobile
      })}
      onClick={onClick}
      data-cy-participant={participant.identity}
    >
      <div className={clsx(classes.infoContainer, ['network-quality'], { [classes.hideVideo]: !isVideoEnabled, [classes.infoContainerMobile]: isMobile })}>
        <div className={classes.infoRow}>
          <NetworkQualityLevel qualityLevel={networkQualityLevel} isMainSpeaker={false}/>
        </div>
      </div>

        <div className = {clsx(classes.muteVideoContainer,['video-off'], classes.muteMediaStyle, {[classes.muteVideoContainerMobile]: isMobile})}>
                {!isVideoEnabled && <VideocamOff />}
        </div>
        <div className = {clsx(classes.muteAudioContainer, ['voice-off'], classes.muteMediaStyle)}>
                {!isAudioEnabled && <MicOff data-cy-audio-mute-icon />}
        </div>
        <div className = {clsx(classes.screenShareContainer, ['screenshare'], classes.muteMediaStyle)}>
            {isScreenShareEnabled && <ScreenShare />}
        </div>
      <div className={classes.infoRow}>
          <h4 className={clsx(classes.identity, ['user-item'], {[classes.identityMobile]: isMobile})}>
            {/* <ParticipantConnectionIndicator participant={participant} /> */}
            {participant.identity}
          </h4>
            <div>
                <PinIcon isSelected={isSelected}/>
            </div>
        </div>
      {/* {isVideoSwitchedOff && <BandwidthWarning />} */}
      {children}
    </div>
  );
}
