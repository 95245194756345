import React, { useState } from 'react';
import clsx from 'clsx';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';

import useRoomState from './hooks/useRoomState/useRoomState';
import useResponsiveQuery from './hooks/useResponsiveQuery/useResponsiveQuery';
import useMeetingDetails from './hooks/useMeetingDetails/useMeetingDetails';
import { isChromeIOS, isMobileTablet } from './UnsupportedBrowserWarning';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '0 30px',
      marginBottom: '0',
      minHeight: '100vh',
    },
    containerMobile: {
      padding: 0,
    },
    leftContainerDis: {
      display: 'inline-flex',
      flexDirection: 'column',
      boxFlex: 1,
      flexGrow: 1,
      maxWidth: '740px',
      position: 'relative',
    },
    rightContainerDefault: {
      height: 'auto',
      position: 'relative',
      marginLeft: '45px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      display: 'flex',
    },
    rightContainer: {
      padding: '0',
      margin: '0',
      flex: 'unset',
    },
    rightContainerMobile: {
      padding: '20px 15px',
      background: '#fff',
      borderTopLeftRadius: '23px',
      borderTopRightRadius: '23px',
      marginTop: '-25px',
      position: 'relative',
      margin: '0',
      height: 'auto',
      width: '100%',
    },
    leftContainerConn: {
      flex: '0 0 100%',
      maxWidth: 'unset',
      marginTop: 0,
    },
    mainHeader: {
      color: '#141414',
      letterSpacing: '8px',
      fontSize: '34px',
      lineHeight: '1',
      textTransform: 'uppercase',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '6px',
      marginBottom: '0',
    },
    mainHeaderMobile: {
      position: 'absolute',
      display: 'block',
      width: '100%',
      textAlign: 'center',
      top: '20px',
      lineHeight: 'normal',
      letterSpacing: '0.52px',
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 'normal',
      zIndex: 9,
      margin: 0,
      textTransform: 'capitalize',
    },
    containerContent: {
      flexGrow: 1,
      overflow: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mainHeaderConn: {
      display: 'none',
    },
    roomMainWrapper: {
      //height: '400px',
    },
    roomMainWrapperMobile: {
      height: '50vh',
    },
    invalidCallContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: 'auto',
      padding: '100px 25px 25px 25px',
    },
    invalidMeetingId: {
      fontSize: '2.25rem',
      fontWeight: 'normal',
      letterSpacing: '0',
      lineHeight: '2.75rem',
      color: '#3c4043',
      maxWidth: '700px',
      textAlign: 'center',
    },
    invalidMsg: {
      fontSize: '1rem',
      fontWeight: 'normal',
      lineHeight: '1.5rem',
      color: '#3c4043',
      maxWidth: '700px',
      textAlign: 'center',
      marginTop: '24px',
    },
  })
);

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  //   height: '100vh',
});

const Main = styled('main')({
  position: 'relative',
});

export default function App() {
  const roomState = useRoomState();
  const isMobile = useResponsiveQuery();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { isMeetingValid } = useMeetingDetails();

  console.log('isMobile', isMobile);
  const classes = useStyles();

  const handleSidebarStatus = status => {
    setIsSidebarOpen(!status);
  };
  if (isMobileTablet() && isChromeIOS()) {
    return (
      <Container className={roomState === 'disconnected' ? classes.container : ''}>
        <div className={classes.invalidCallContainer}>
          <div className={classes.invalidMeetingId}>Chrome is not supported</div>
          <div className={classes.invalidMsg}>
            Please open your video meeting URL in a Safari browser window to proceed with your video meeting
          </div>
        </div>
      </Container>
    );
  }
  if (isMeetingValid) {
    return (
      <Container
        className={clsx({ [classes.container]: roomState === 'disconnected', [classes.containerMobile]: isMobile })}
      >
        {roomState === 'disconnected' ? (
          <h1
            className={clsx(['logo-wrapper'], {
              [classes.mainHeader]: roomState === 'disconnected',
              [classes.mainHeaderMobile]: isMobile && roomState === 'disconnected',
              [classes.mainHeaderConn]: roomState !== 'disconnected',
            })}
          >
            <img src={require('./assets/images/Clientela_Logo_black.png')} />
          </h1>
        ) : (
          ''
        )}

        <Grid container direction="row" className={clsx({ [classes.containerContent]: !isMobile })}>
          <div
            className={clsx(['left-side-content'], {
              [classes.leftContainerDis]: roomState === 'disconnected',
              [classes.leftContainerConn]: isMobile || roomState !== 'disconnected',
            })}
          >
            <Main
              className={clsx(classes.roomMainWrapper, {
                [classes.roomMainWrapperMobile]: roomState === 'disconnected' && isMobile,
              })}
            >
              {roomState === 'disconnected' ? (
                <LocalVideoPreview />
              ) : (
                <Room handleSidebarStatus={handleSidebarStatus}></Room>
              )}
              {isMobile && roomState === 'disconnected' ? '' : <Controls isSidebarOpen={isSidebarOpen} />}
            </Main>
          </div>
          <div
            className={clsx(classes.rightContainerDefault, ['right-side-content'], {
              [classes.rightContainerMobile]: isMobile && roomState === 'disconnected',
              [classes.rightContainer]: roomState !== 'disconnected',
            })}
          >
            {roomState === 'disconnected' ? <MenuBar /> : ''}
          </div>
        </Grid>
        <ReconnectingNotification />
      </Container>
    );
  } else {
    return (
      <Container className={roomState === 'disconnected' ? classes.container : ''}>
        <div className={classes.invalidCallContainer}>
          <div className={classes.invalidMeetingId}>Invalid meeting ID.</div>
          <div className={classes.invalidMsg}>Please check the link or meeting code before trying again.</div>
        </div>
      </Container>
    );
  }
}
