import React from 'react';
import { createMuiTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    sidebarWidth: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
  }
}

export default createMuiTheme({
  typography: {
    fontFamily: 'SFProDisplay, sans-serif',
  },
  palette: {
    // type: 'dark',
    background: {
      default: '#fff',
    },
    primary: {
      main: '#000',
    },
  },
  sidebarWidth: 260,
});
