import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import useRoomState from '../../../hooks/useRoomState/useRoomState';

import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: 0,
    },
    conFab: {
      marginRight: '30px',
    },
  })
);

export default function ToggleVideoButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const roomState = useRoomState();
  const [isVideoEnabled, disableVideoMute, toggleVideoEnabled] = useLocalVideoToggle();

  return (
    <Tooltip
      title={isVideoEnabled ? 'Turn off Video' : 'Turn on Video'}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab
        style={{ background: isVideoEnabled ? '#4CAF50' : '#F44336' }}
        className={roomState === 'disconnected' ? classes.fab : classes.conFab}
        onClick={toggleVideoEnabled}
        disabled={props.disabled || disableVideoMute}
      >
        {isVideoEnabled ? (
          <Videocam style={{ color: '#fff', fontSize: '32px' }} />
        ) : (
          <VideocamOff style={{ color: '#fff', fontSize: '32px' }} />
        )}
      </Fab>
    </Tooltip>
  );
}
