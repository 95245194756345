import React, { useRef, useEffect } from 'react';
import { IVideoTrack } from '../../types';
import { styled, createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Track } from 'twilio-video';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useResponsiveQuery from '../../hooks/useResponsiveQuery/useResponsiveQuery';

const Video = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoTrack: {
      borderRadius: '8px',
      //boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    },
    videoTrackConnected: {
      objectFit: 'cover',
      height: '100%',
    },
    videoTrackConnectedScreen: {
      objectFit: 'contain',
      height: '100%',
    },
  })
);

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
  trackName?: string;
}

export default function VideoTrack({ track, isLocal, priority, trackName }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  const roomState = useRoomState();
  const classes = useStyles();
  const isMobile = useResponsiveQuery();

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored.
  const style = (trackName !== 'screen') ? { transform: 'rotateY(180deg)' } : {};

  return (
    <Video
      className={
        roomState === 'disconnected' && !isMobile
          ? classes.videoTrack
          : trackName === 'screen'
          ? classes.videoTrackConnectedScreen
          : classes.videoTrackConnected
      }
	  ref={ref}
	  style={style}
      id={isLocal ? 'localvideotrack' : 'remotevideotrack'}
    />
  );
}
