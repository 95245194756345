import React from 'react';
import clsx from 'clsx';
import { styled, createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { LocalParticipant, RemoteParticipant } from 'twilio-video';
import useResponsiveQuery from '../../hooks/useResponsiveQuery/useResponsiveQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '115px',
      marginBottom: '10px',
      background: '#fff'
    },
    containerMobile: {
      height: '81px',
      marginBottom: '10px',
      background: '#fff'
    },
  })
);

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
}));

const TrackContainer = styled('div')(({ theme }) => ({
  width: '168px',
  flex: '1',
}));

interface ParticipantProps {
  participant: LocalParticipant | RemoteParticipant;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  onClick: () => void;
  isSelected: boolean;
}

export default function Participant({
  participant,
  disableAudio,
  enableScreenShare,
  onClick,
  isSelected,
}: ParticipantProps) {
  const classes = useStyles();
  const isMobile = useResponsiveQuery();

  return (
    <Container className={clsx(['participant-item'],{ [classes.container]: !isMobile, [classes.containerMobile]: isMobile })}>
      <TrackContainer className="participant-view">
        <ParticipantTracks
          participant={participant}
          disableAudio={disableAudio}
          enableScreenShare={enableScreenShare}
          isMainSpeaker={false}
        />
      </TrackContainer>
      <ParticipantInfo participant={participant} onClick={onClick} isSelected={isSelected}>
      </ParticipantInfo>
      
    </Container>
  );
}
