import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ToggleFullscreenButton from '../ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Menu from './Menu/Menu';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography } from '@material-ui/core';
import { MediaOptions } from '../MediaOptions/MediaOptions';
import useResponsiveQuery from '../../hooks/useResponsiveQuery/useResponsiveQuery';
import Controls from '../Controls/Controls';
import useMeetingDetails from '../../hooks/useMeetingDetails/useMeetingDetails';

const { connect, createLocalTracks } = require('twilio-video');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      padding: '0',
    },
    container: {
      backgroundColor: theme.palette.background.default,
    },
    containerMobile: {
      backgroundColor: 'unset',
      width: '100%',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    textField: {
      marginTop: '26px',
      lineHeight: 'normal',
      letterSpacing: '0.52px',
      color: '#171717',
      marginBottom: '0',
      fontSize: '16px',
      fontWeight: 'normal',
      borderRadius: '8px',
      backgroundColor: '#ffffff',
      '& > div > input': {
        paddingTop: '0',
        paddingBottom: '0',
        height: '50px',
        // fontFamily: 'GothamBook, sans-serif',
        color: '#171717',
        background: '#f7f7f7',
        border: 'none',
        borderRadius: '8px',
        transition: 'all 0.3s',
        '&:hover, &:focus': {
          background: '#efefef',
          transition: 'all 0.3s',
        },
      },
      '& > div > input::placeholder': {
        color: '#bbb',
        opacity: 1,
      },
      '&  fieldset': {
        border: 'none',
      },
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      marginLeft: '2.2em',
      minWidth: '200px',
      fontWeight: 600,
    },
    meetingNameHeading: {
      marginTop: '0',
      marginBottom: '19px',
      letterSpacing: '1.31px',
      color: '#3a3a3a',
      fontSize: '40px',
      lineHeight: 'normal',
      fontWeight: 'normal',
    },
    meetingNameHeadingMobile: {
      marginTop: '0',
      marginBottom: '8px',
      letterSpacing: '0.59px',
      color: '#3a3a3a',
      fontSize: '18px',
      lineHeight: 'normal',
      fontWeight: 'normal',
    },
    meetingPurpose: {
      lineHeight: 'normal',
      letterSpacing: '0.82px',
      color: '#000000',
      fontSize: '25px',
      fontWeight: 'normal',
      marginTop: '0',
      marginBottom: '26px',
    },
    meetingTime: {
      lineHeight: 'normal',
      letterSpacing: '0.66px',
      color: '#7a7a7a',
      fontSize: '20px',
      fontWeight: 'normal',
      marginTop: '0',
      marginBottom: '0',
    },
    meetingTimeMobile: {
      lineHeight: 'normal',
      letterSpacing: '0.66px',
      color: '#7a7a7a',
      fontSize: '15px',
      fontWeight: 'normal',
      marginTop: '0',
      marginBottom: '0',
    },
    meetingRoom: {
      lineHeight: 'normal',
      letterSpacing: '0.66px',
      color: '#7a7a7a',
      fontSize: '20px',
      fontWeight: 'normal',
      marginTop: '0',
      marginBottom: '0',
    },
    meetingRoomMobile: {
      lineHeight: 'normal',
      letterSpacing: '0.49px',
      color: '#7a7a7a',
      fontSize: '15px',
      fontWeight: 'normal',
      marginTop: '0',
      marginBottom: '0',
    },
    mediaOptionsStyle: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '26px',
    },
    mediaOptionsMobileStyle: {
      marginTop: '19px',
    },
    mediaOptionsSelect: {
      height: '33px',
    },
    mediaOptionsLable: {
      lineHeight: 'normal',
      letterSpacing: '0.52px',
      color: '#3a3a3a',
      fontSize: '16px',
      fontWeight: 'normal',
      marginBottom: '12px',
    },
    infoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    infoContainerMobile: {
      '& > div': {
        flex: '0 0 250px',
      },
    },
    joinButtonMobile: {
      position: 'unset',
      top: '0',
      marginTop: '25px !important',
      width: '100px',
      right: '0',
    },
    joinButtonDesktop: {
      height: '50px',
      letterSpacing: '2px',
      width: '100%',
    },
    joinButtonDesktopWrapper: {
      position: 'relative',
      '& > div': {
        bottom: '11px',
        '& > div': {
          width: '25px !important',
          height: '25px !important',
          margin: '0',
        },
      },
    },
    nameInputField: {
      paddingTop: '14px',
      paddingBottom: '14px',
    },
    loaderWrapper: {
      position: 'absolute',
      bottom: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  })
);

const ColorButton = withStyles(theme => ({
  root: {
    color: '#fff',
    marginTop: '26px',
    backgroundColor: '#4CAF50',

    '&:hover': {
      backgroundColor: '#4CAF50',
    },
  },
}))(Button);

export default function MenuBar() {
  const classes = useStyles();
  const { URLRoomName } = useParams();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect } = useVideoContext();
  const { isMeetingValid, meetingDetail } = useMeetingDetails();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const isMobile = useResponsiveQuery();

  useEffect(() => {
    if (URLRoomName) {
      if (isMeetingValid) {
        setRoomName(URLRoomName);
      }
    }
  }, [URLRoomName]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  //   const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
  //     setRoomName(event.target.value);
  //   };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL beacuse routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}`));
    }
    getToken(name, roomName).then(token => connect(token));
  };

  return (
    <div className={clsx({ [classes.container]: !isMobile, [classes.containerMobile]: isMobile })}>
      <div>{isMobile ? <Controls /> : ''}</div>

      <div className={clsx(classes.infoContainer, { [classes.infoContainerMobile]: isMobile })}>
        {meetingDetail && Object.keys(meetingDetail).length > 0 && (
          <div>
            {meetingDetail.appointment_name && (
              <p
                className={clsx({ [classes.meetingPurpose]: !isMobile, [classes.meetingNameHeadingMobile]: isMobile })}
              >
                {meetingDetail.appointment_name}
              </p>
            )}
            {meetingDetail.start_time && meetingDetail.end_time && (
              <p className={clsx({ [classes.meetingTime]: !isMobile, [classes.meetingTimeMobile]: isMobile })}>
                {meetingDetail.start_time} - {meetingDetail.end_time}
              </p>
            )}
            {meetingDetail.client_name && (
              <p className={clsx({ [classes.meetingRoom]: !isMobile, [classes.meetingRoomMobile]: isMobile })}>
                Appointment with {meetingDetail.client_name}
              </p>
            )}
          </div>
        )}
      </div>
      <Toolbar className={classes.toolBar}>
        {roomState === 'disconnected' ? (
          <form className={classes.form} onSubmit={handleSubmit}>
            {!user?.displayName ? (
              <TextField
                id="menu-name"
                placeholder="your name"
                className={classes.textField}
                value={name}
                onChange={handleNameChange}
                variant="outlined"
                margin="dense"
              />
            ) : (
              <Typography className={classes.displayName} variant="body1">
                {user.displayName}
              </Typography>
            )}
            <Hidden lgDown lgUp>
              <TextField
                id="menu-room"
                label="Room"
                className={classes.textField}
                value={roomName}
                variant="outlined"
                // onChange={handleRoomNameChange}
                margin="dense"
              />
            </Hidden>
            <div
              className={clsx(classes.mediaOptionsStyle, ['video-input-select'], {
                [classes.mediaOptionsMobileStyle]: isMobile,
              })}
            >
              <label className={classes.mediaOptionsLable}>Video Input</label>
              <MediaOptions name="videoinput" username={name} />
            </div>
            {/* <div className = {clsx(classes.mediaOptionsStyle, {[classes.mediaOptionsMobileStyle]: isMobile })}>
                        <label className = {classes.mediaOptionsLable}>Audio Input</label>
                            <MediaOptions name="audioinput"  username={name}/>
                    </div>
                    <div className = {clsx(classes.mediaOptionsStyle, {[classes.mediaOptionsMobileStyle]: isMobile })}>
                        <label className = {classes.mediaOptionsLable}>Audio Output</label>
                        <MediaOptions name="audiooutput" username={name}/>
                    </div> */}

            {/* {!isMobile &&  */}

            <div className={classes.joinButtonDesktopWrapper}>
              <ColorButton
                className={clsx(classes.joinButtonDesktop, { [classes.joinButtonMobile]: isMobile })}
                type="submit"
                color="primary"
                variant="contained"
                disabled={isConnecting || !name || !roomName || isFetching}
              >
                Join
              </ColorButton>
              {/* } */}

              {(isConnecting || isFetching) && (
                <div className={classes.loaderWrapper}>
                  <CircularProgress className={classes.loadingSpinner} />{' '}
                </div>
              )}
            </div>
          </form>
        ) : (
          <h3>{roomName}</h3>
        )}
        {/* <ToggleFullscreenButton /> */}
        {/* <Menu /> */}
      </Toolbar>
    </div>
  );
}
