const API = {
  DysonBaseURL: 'https://dyson-demo.com',
  DysonConsultBaseURL: 'https://consultation.dyson-demo.com',
  BenefitBaseURL: 'https://consultation.benefitbrowbars.com',
  ReissBaseURL: 'https://consultation.reissretail.com',
  LiplabBaseURL: 'https://liplabboutique.com',
  ClientelaBaseURL: 'https://cloud.clientela.com',
  MagasinBaseURL: 'https://service.magasin.dk/',
  JhilburnBaseURL: 'https://clientela.myjhilburn.com',
  JhilburnDevBaseURL: 'https://dev-jhilburn.clientela.com',
  ReissNewBaseURL: 'https://reiss.clientela.com',
  CoralaiBaseURL: 'https://booking.coralai.io',
  CoralaiStagingBaseURL: 'https://coralai-staging.clientela.com',
  STJKBaseURL: 'https://sjk-staging.clientela.com',
  STJKProdBaseURL: 'https://clientele.stjohnknits.com',
};

const DEV_TOOLS = {
  logError: true,
};

module.exports = {
  API,
  DEV_TOOLS,
};
