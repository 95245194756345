import React from 'react';
import clsx from 'clsx';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalParticipant, RemoteParticipant, Track } from 'twilio-video';
import Publication from '../Publication/Publication';
import usePublications from '../../hooks/usePublications/usePublications';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';
import useResponsiveQuery from '../../hooks/useResponsiveQuery/useResponsiveQuery';

interface ParticipantTracksProps {
  participant: LocalParticipant | RemoteParticipant;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  videoPriority?: Track.Priority | null;
  isMainSpeaker: boolean;
}

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noPhoto: {
      width: '119px',
      height: '119px',
      backgroundColor: '#777777',
      borderRadius: '100%',
      lineHeight: 'normal',
      letterSpacing: '1.31px',
      textAlign: 'center',
      color: '#ffffff',
      fontSize: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    noPhotoSide: {
      width: '37px',
      height: '37px',
      fontSize: '18px',
    },
    noPhotoMainWrapper: {
      background: 'unset',
      display: 'flex',
      width: '482px',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    noPhotoMainWrapperMobile: {
      width: '100%',
    },
    noPhotoSideWrapper: {
      background: '#ececec',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      height: '100%',
      alignItems: 'center',
    },
    noVideoMainWrapper: {
      height: '100vh',
    },
    noVideoSideWrapper: {
      height: '100%',
      borderRadius: '5px',
      overflow: 'hidden',
    },
  })
);

const Container = styled('div')({
  width: '100%',
  objectFit: 'contain',
  backgroundColor: '#000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
  fontSize: '20px',
});

export default function ParticipantTracks({
  participant,
  disableAudio,
  enableScreenShare,
  videoPriority,
  isMainSpeaker,
}: ParticipantTracksProps) {
  const { room } = useVideoContext();
  const classes = useStyles();
  const mainParticipant = useMainSpeaker();
  const publications = usePublications(participant);

  const isLocal = participant === room.localParticipant;
  const isMain = mainParticipant === participant;
  const isMobile = useResponsiveQuery();

  if (isLocal) {
    console.log(publications);
  }

  let filteredPublications;

  if (enableScreenShare && publications.some(p => p.trackName === 'screen')) {
    filteredPublications = publications.filter(p => p.trackName !== 'camera');
  } else {
    filteredPublications = publications.filter(p => p.trackName !== 'screen');
  }

  const videoPublication = publications.filter(p => p.kind == 'video');

  console.log(disableAudio);

  return (
    <>
      {filteredPublications.map(publication => (
        <Publication
          key={publication.kind}
          publication={publication}
          participant={participant}
          isLocal={isLocal}
          disableAudio={disableAudio}
          videoPriority={videoPriority}
        />
      ))}

      {videoPublication.length == 0 ? (
        <Container className={isMainSpeaker ? classes.noVideoMainWrapper : classes.noVideoSideWrapper}>
          <div
            className={clsx({
              [classes.noPhotoMainWrapper]: isMainSpeaker,
              [classes.noPhotoSideWrapper]: !isMainSpeaker,
              [classes.noPhotoMainWrapperMobile]: isMobile,
            }, ['no-photo-wrapper'])}
          >
            <img
              className={clsx({ [classes.noPhotoSide]: !isMainSpeaker }, classes.noPhoto)}
              src={require('../../assets/images/no-video.png')}
              alt="no video"
            />
          </div>
        </Container>
      ) : null}
    </>
  );
}
