import React from 'react';
import { styled } from '@material-ui/core/styles';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  '& div': {
    width: '2px',
    // border: '1px solid black',
    borderRadius: '4px',
    boxSizing: 'content-box',
    '&:not(:last-child)': {
      borderRight: 'none',
    },
  },
});

export default function NetworkQualityLevel({
  qualityLevel,
  isMainSpeaker,
}: {
  qualityLevel: number | null;
  isMainSpeaker: boolean;
}) {
  const STEP = isMainSpeaker ? 10 : 4;
  const MARGIN = isMainSpeaker ? '5px' : '3px';
  const WIDTH = isMainSpeaker ? '5px' : '3px';
  if (qualityLevel === null) return null;
  return (
    <Container>
      {[0, 1, 2].map(level => (
        <div
          key={level}
          style={{
            height: `${STEP * (level + 1)}px`,
            background: qualityLevel > level ? '#0c0' : '#040',
            width: WIDTH,
            marginRight: MARGIN,
          }}
        />
      ))}
    </Container>
  );
}
