import EventEmitter from 'events';
import { useCallback, useEffect, useRef, useState } from 'react';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Callback } from '../../../types';
import { useParams } from 'react-router-dom';
const Chat = require('twilio-chat');

export default function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const [chatChannel, setChatChannel] = useState<any>();
  const { URLRoomName } = useParams();
  const [roomName, setRoomName] = useState(URLRoomName);
  const [isConnecting, setIsConnecting] = useState(false);
  const disconnectHandlerRef = useRef<() => void>(() => {});
  const localTracksRef = useRef<LocalTrack[]>([]);

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
  }, [localTracks]);

  const connect = useCallback(
    token => {
        console.log("test connecting   ......");
      setIsConnecting(true);
      return Video.connect(token, { ...options, tracks: [] }).then(
        newRoom => {
          setRoom(newRoom);
          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(new EventEmitter() as Room));
            window.removeEventListener('beforeunload', disconnectHandlerRef.current);
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          localTracksRef.current.forEach(track =>
            // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
            // However, tracks must be published manually in order to set the priority on them.
            // All video tracks are published with 'low' priority. This works because the video
            // track that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            newRoom.localParticipant.publishTrack(track, { priority: track.kind === 'video' ? 'low' : 'standard' })
          );

          disconnectHandlerRef.current = () => newRoom.disconnect();

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnectHandlerRef.current);

            Chat.Client.create(token).then(client => {
                console.log('Created chat client');
                joinGeneralChannel(client)
                .then(result =>{})
                .catch(error => {
                    console.log(error);
                });
        
            }).catch(error => {
                console.error(error);
                console.log('There was an error creating the chat client:<br/>' + error);
                console.log('Please check your .env file.');
            });

            const joinGeneralChannel = (chatClient) => {
                return new Promise((resolve, reject) => {
                  chatClient.getSubscribedChannels().then(() => {
                    chatClient.getChannelByUniqueName(roomName).then((channel) => {
                        channel.join().then(() => {
                            console.log(`Joined general channel as`);
                            setChatChannel(channel);

                            channel.on('memberLeft', (member) => {
                              console.log("left");
                              // Reset the room only after all other `disconnected` listeners have been called.
                              if(member.identity === newRoom.localParticipant.identity) {
                                setChatChannel(null);
                                channel.removeAllListeners();
                              }
                            });
                            window.addEventListener('beforeunload', () => {
                                channel.leave();
                            });
                            // window.localStorage.setItem("chat_client", JSON.stringify(chatClient));
                            // this.addMessage({ body: `Joined general channel as ${this.state.username}` })
                          }).catch((error) => {
                            channel.leave();
                            joinGeneralChannel(chatClient);
                            console.log(error);
                            // reject(Error('Could not join general channel.'))
                          });
                    }).catch(() => createGeneralChannel(chatClient))
                  }).catch(() => reject(Error('Could not get channel list.')))
                })
              }
            
            const createGeneralChannel = (chatClient) => {
              console.log("cretae general chat");
                return new Promise((resolve, reject) => {
                  chatClient
                    .createChannel({ uniqueName: roomName, friendlyName: 'General Chat' })
                    .then(() => joinGeneralChannel(chatClient))
                    .catch(() => reject(Error('Could not create general channel.')))
                })
            }
            setIsConnecting(false);
        },
        error => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [options, onError]
  );

  return { room, isConnecting, connect, chatChannel };
}
