import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../config';

interface meetingDetail {
  client_name?: string;
  start_time?: string;
  end_time?: string;
  appointment_name?: string;
}

export default function useMeetingDetails() {
  const { URLRoomName } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');
  const source = urlParams.get('source');
  let general_meet_param = '';
  const [isMeetingValid, setMeetingValidity] = useState<boolean>(true);
  const [meetingDetail, setMeetingDetails] = useState<meetingDetail>({});

  const strRot13 = str => {
    return (str + '').replace(/[a-zA-Z]/gi, function(s) {
      return String.fromCharCode(s.charCodeAt(0) + (s.toLowerCase() < 'n' ? 13 : -13));
    });
  };

  useEffect(() => {
    if (URLRoomName) {
      //rotate room name by 13 similar to str_rot13 of php
      const str_rot13 = strRot13(URLRoomName);
      const project_name = str_rot13 ? str_rot13.split('-')[1] : '';
      console.log(project_name);
      let BaseURL = '';
      // select base url bases on the project name
      switch (project_name) {
        case 'dyson':
          BaseURL = API.API.DysonBaseURL;
          break;
        case 'dysonconsult':
          BaseURL = API.API.DysonConsultBaseURL;
          break;
        case 'benefit':
          BaseURL = API.API.BenefitBaseURL;
          break;
        case 'reissconsult':
          BaseURL = API.API.ReissBaseURL;
          break;
        case 'liplab':
          BaseURL = API.API.LiplabBaseURL;
          break;
        case 'clientela':
          BaseURL = API.API.ClientelaBaseURL;
          if (type && source) {
            general_meet_param = `&type=${type}&source=${source}`;
          }
          break;
        case 'magasin':
          BaseURL = API.API.MagasinBaseURL;
          break;
        case 'jhilburn':
          BaseURL = API.API.JhilburnBaseURL;
          break;
        case 'jhilburndev':
          BaseURL = API.API.JhilburnDevBaseURL;
          break;
        case 'reiss':
          BaseURL = API.API.ReissNewBaseURL;
          break;
        case 'coralai':
          BaseURL = API.API.CoralaiBaseURL;
          break;
        case 'coralaiStaging':
          BaseURL = API.API.CoralaiStagingBaseURL;
          break;
        case 'st_john_knits':
          BaseURL = API.API.STJKBaseURL;
          break;
        case 'st_john_knits_prod':
          BaseURL = API.API.STJKProdBaseURL;
          break;
      }

      if (BaseURL && BaseURL != '') {
        fetch(`${BaseURL}/api/meetings-details?meeting_id=${URLRoomName}${general_meet_param}`)
          .then(res => res.json())
          .then(result => {
            console.log(result.data);
            if (result.data && Object.keys(result.data).length) {
              setMeetingDetails(result.data);
              setMeetingValidity(true);
            } else {
              setMeetingValidity(false);
            }
          })
          .catch(err => {
            setMeetingValidity(false);
            console.log(err);
          });
      } else {
        setMeetingValidity(false);
      }
    } else {
      setMeetingValidity(false);
    }
  }, [URLRoomName]);

  return { isMeetingValid, meetingDetail };
}
