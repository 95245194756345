import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Tooltip from '@material-ui/core/Tooltip';
import useRoomState from '../../../hooks/useRoomState/useRoomState';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      marginRight: '25px',
    },
    conFab: {
      marginRight: '30px',
    },
  })
);

export default function ToggleAudioButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const roomState = useRoomState();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  return (
    <Tooltip
      title={isAudioEnabled ? 'Mute Audio' : 'Unmute Audio'}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab
        style={{ background: isAudioEnabled ? '#4CAF50' : '#f44336' }}
        className={roomState === 'disconnected' ? classes.fab : classes.conFab}
        onClick={toggleAudioEnabled}
        disabled={props.disabled}
        data-cy-audio-toggle
      >
        {isAudioEnabled ? (
          <Mic style={{ color: '#fff', fontSize: '32px' }} />
        ) : (
          <MicOff style={{ color: '#fff', fontSize: '32px' }} />
        )}
      </Fab>
    </Tooltip>
  );
}
