const API = {
  DysonBaseURL: 'http://localhost/dyson/web',
  DysonConsultBaseURL: 'http://localhost/Clientela/benefitvirtualappt/web',
  BenefitBaseURL: 'http://localhost/benefit_consultation/web',
  ReissBaseURL: 'http://localhost/reiss_consultation/web',
  LiplabBaseURL: 'http://localhost/liplab/web',
  ClientelaBaseURL: 'http://localhost/clientela-multitenancy/web',
  MagasinBaseURL: 'http://localhost/magasin/web',
  JhilburnBaseURL: 'http://localhost/jhilburn/web',
  JhilburnDevBaseURL: 'http://localhost/jhilburn/web',
  ReissNewBaseURL: 'http://localhost/reiss_latest/web',
  CoralaiBaseURL: 'http://localhost/corpus/web',
  CoralaiStagingBaseURL: 'http://localhost/corpus/web',
  STJKBaseURL: 'http://localhost/saint_john_staging/web',
  STJKProdBaseURL: 'http://localhost/saint_john/web',
};

const DEV_TOOLS = {
  enableReduxDevTools: true,
  logError: true,
};

module.exports = {
  API,
  DEV_TOOLS,
};
