import React from 'react';
import clsx from 'clsx';
import { styled, createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useResponsiveQuery from '../../hooks/useResponsiveQuery/useResponsiveQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoTrack: {
      borderRadius: '8px',
      //boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)'
    },
    noPhoto: {
      width: '119px',
      height: '119px',
      backgroundColor: '#777777',
      borderRadius: '100%',
      lineHeight: 'normal',
      letterSpacing: '1.31px',
      textAlign: 'center',
      color: '#ffffff',
      fontSize: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    noPhotoMainWrapper: {
      background: 'unset',
      display: 'flex',
      width: '482px',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    noPhotoMainWrapperMobile: {
      width: '100%',
    },
    noVideoMainWrapper: {
      height: '416px',
      borderRadius: '8px',
    },
    noVideoMainWrapperMobile: {
      height: '100%',
      borderRadius: '0'
    },
  })
);

export default function LocalVideoPreview() {
  const Container = styled('div')({
    width: '100%',
    objectFit: 'contain',
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF',
    fontSize: '20px',
  });

  const classes = useStyles();
  const isMobile = useResponsiveQuery();

  const { localTracks } = useVideoContext();
  console.log(localTracks);
  console.log('localTracks');

  const videoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack;

  return videoTrack ? (
    <VideoTrack track={videoTrack} isLocal />
  ) : (
    <Container className={clsx(classes.noVideoMainWrapper, { [classes.noVideoMainWrapperMobile]: isMobile })}>
      <div className={clsx(classes.noPhotoMainWrapper, { [classes.noPhotoMainWrapperMobile]: isMobile })}>
        <img className={classes.noPhoto} src={require('../../assets/images/no-video.png')} alt="no video" />
      </div>
    </Container>
  );
}
