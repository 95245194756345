import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      marginRight: '30px',
      background: '#F44336',
      '&:hover': {
        background: '#F44336',
      },
    },
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const { room, chatChannel } = useVideoContext();

  const endCall = () => {
    room.disconnect();
    if (chatChannel) {
      chatChannel.leave();
    }
  };
  return (
    <Tooltip title={'End Call'} onClick={endCall} placement="top" PopperProps={{ disablePortal: true }}>
      <Fab className={classes.fab}>
        <CallEnd style={{ color: '#fff', fontSize: '32px' }} />
      </Fab>
    </Tooltip>
  );
}
