import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import EndCallButton from './EndCallButton/EndCallButton';
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from './ToogleScreenShareButton/ToggleScreenShareButton';

import useIsUserActive from './useIsUserActive/useIsUserActive';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useResponsiveQuery from '../../hooks/useResponsiveQuery/useResponsiveQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      marginTop: '30px',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 45px',
      zIndex: 1,
      position: 'absolute',
      bottom: '30px',
    },
    containerMobile: {
      marginTop: '0px',
      marginBottom: '19px',
      backgroundColor: 'transparent !important',
      position: 'unset',
      margin: '0',
      padding: '0',
    },
    containerConnected: {
      backgroundColor: 'transparent',
      margin: '0',
      height: '100px',
      transition: 'opacity 0.6s, transform 0.6s, visibility 0s 0.6s',
      opacity: 0,
      visibility: 'hidden',
      position: 'fixed',
      bottom: 0,
      zIndex: 9999,
      '&.showControls, &:hover': {
        transition: 'opacity 0.6s, transform 0.6s, visibility 0s',
        opacity: 1,
        visibility: 'visible',
      },
    },
  })
);

export default function Controls(isSidebarOpen) {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const isUserActive = useIsUserActive();
  const showControls = isUserActive || roomState === 'disconnected';
  const isMobile = useResponsiveQuery();
  console.log("isSidebarOpen", isSidebarOpen)
  return (
    <div
      className={clsx(classes.container, ['option-btns'], {
        showControls,
        [classes.containerConnected]: roomState !== 'disconnected',
        [classes.containerMobile]: isMobile,
        ['sidebarEnabled']: isSidebarOpen.isSidebarOpen
      })}
    >
      <ToggleAudioButton disabled={isReconnecting} />
      <ToggleVideoButton disabled={isReconnecting} />

      {roomState !== 'disconnected' && (
        <>
          <EndCallButton />
        </>
      )}
      {roomState !== 'disconnected' && !isMobile && (
        <>
          <ToggleScreenShareButton disabled={isReconnecting} />
        </>
      )}
    </div>
  );
}
