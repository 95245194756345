import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { LocalParticipant, RemoteParticipant, RemoteVideoTrack, LocalVideoTrack } from 'twilio-video';

import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import VideocamOff from '@material-ui/icons/VideocamOff';
import NetworkQualityLevel from '../NewtorkQualityLevel/NetworkQualityLevel';
import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useResponsiveQuery from '../../hooks/useResponsiveQuery/useResponsiveQuery';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  isVideoSwitchedOff: {
    '& video': {
      filter: 'blur(4px) grayscale(1) brightness(0.5)',
    },
  },
  identity: {
    position: 'absolute',
    bottom: '40px',
    left: '14px',
    background: 'rgba(169, 169, 169,  0.5)',
    width: '90px',
    margin: '0',
    opacity: '0.87',
    borderRadius: '9px',
    textTransform: 'capitalize',
    fontSize: '15px',
    padding: '10px',
    lineHeight: '1',
    letterSpacing: '0.49px',
    color: '#fff',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  identityMain: {
    top: '30px',
    right: '14px',
    bottom: 'auto',
    left: 'auto',
    background: '#525252',
    border: 'none'
  },
  identitySingleParticipant: {
    width: 'auto',
  },
  infoContainer: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    // padding: '0.4em',
    width: '100%',
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '42px',
    marginLeft: '32px',
  },
});

interface MainParticipantInfoProps {
  participant: LocalParticipant | RemoteParticipant;
  children: React.ReactNode;
  isLocalParticipant: boolean;
  isSingleParticipant: boolean;
}

export default function MainParticipantInfo({
  participant,
  children,
  isLocalParticipant,
  isSingleParticipant,
}: MainParticipantInfoProps) {
  const classes = useStyles();

  const publications = usePublications(participant);
  const videoPublication = publications.find(p => p.trackName === 'camera');
  const screenSharePublication = publications.find(p => p.trackName === 'screen');
  const isVideoEnabled = Boolean(videoPublication);
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const participants = useParticipants();
  const isMobile = useResponsiveQuery();

  if (isSingleParticipant && isMobile && participants.length == 0) {
    return (
      <div
        data-cy-main-participant
        className={clsx(classes.container, { [classes.isVideoSwitchedOff]: isVideoSwitchedOff })}
      >
        <div className={classes.infoContainer}>
          <h4
            className={clsx(classes.identity, {
              [classes.identityMain]: isLocalParticipant,
              [classes.identitySingleParticipant]: isSingleParticipant,
            })}
          >
            Other Participant will join shortly
          </h4>
        </div>
        {children}
      </div>
    );
  } else {
    return (
      <div
        data-cy-main-participant
        className={clsx(classes.container, { [classes.isVideoSwitchedOff]: isVideoSwitchedOff })}
      >
        <div className={classes.infoContainer}>
          <div className={classes.infoRow}>
            <NetworkQualityLevel qualityLevel={networkQualityLevel} isMainSpeaker={true} />
          </div>
          {
            <h4 className={clsx(classes.identity,['active-user-onMeeting'], { [classes.identityMain]: isLocalParticipant })}>
              <img src={require('../../assets/images/microphone-white.svg')} />
              <span>{participant.identity}</span>
              {/* {!isVideoEnabled && <VideocamOff />} */}
            </h4>
          }
        </div>
        {/* {isVideoSwitchedOff && <BandwidthWarning />} */}
        {children}
      </div>
    );
  }
}
