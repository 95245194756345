import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import clsx from 'clsx';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import { styled, createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PeopleIcon from '@material-ui/icons/People';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import RSC from "react-scrollbars-custom";

import MainParticipant from '../MainParticipant/MainParticipant';
import { ChatWindow } from '../ChatWindow/ChatWindow';
import useResponsiveQuery from '../../hooks/useResponsiveQuery/useResponsiveQuery';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MagicUrl from 'quill-magic-url';
Quill.register('modules/magicUrl', MagicUrl);

const drawerWidth = 320;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainSpeakerContainer: {
      flex: '1',
      height: '100vh',
    },
    mainSpeakerContainerMobile: {
      height: '65vh',
    },
    localSpeakerContainer: {
      width: '100%',
      height: '35vh',
    },
    mediaOptionsStyle: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
    },
    mediaOptionsVideoStyle: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '0',
    },
    mediaOptionsSelect: {
      height: '33px',
    },
    mediaOptionsLable: {
      lineHeight: 'normal',
      letterSpacing: '0.52px',
      color: '#3a3a3a',
      fontSize: '16px',
      fontWeight: 'normal',
      marginBottom: '12px',
    },
    tabLabelStyle: {
      color: '#4CAF50',
    },
    hide: {
      display: 'none',
    },
    openDrawerIcon: {
      position: 'absolute',
      top: '15px',
      right: '38px',
      cursor: 'pointer',
      color: '#fff',
      backgroundColor: '#000',
      zIndex: 99,
      padding: '4px',
      '&:hover': {
        backgroundColor: '#000',
      },
      '& > span > svg': {
        fontSize: '32px',
      },
    },
    openDrawerIconMobile: {
      right: '25px',
      margin: 0,
    },
    closeDrawerIcon: {
      position: 'absolute',
      top: '15px',
      right: '358px',
      cursor: 'pointer',
      color: '#fff',
      backgroundColor: '#c32e3b',
      zIndex: 99,
      padding: '4px',
      '&:hover': {
        backgroundColor: '#c32e3b',
      },
      '& > span > svg': {
        fontSize: '32px',
      },
    },
    closeDrawerIconMobile: {
      right: 'unset',
      left: '15px',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      background: '#f2f2f2',
      zIndex: 99999,
    },
    drawerPaperMobile: {
      width: 'calc(100% - 70px)',
      background: '#f2f2f2',
      height: '100vh',
      zIndex: 9999999,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: 0,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    contentShiftMobile: {
      marginRight: 220,
    },
    peopleSection: {
      paddingLeft: '20px',
      '& > span': {
        padding: '0 10px',
      },
    },
    peopleSectionMobile: {
      paddingLeft: '30px',
      '& > span': {
        padding: '0 10px',
      },
    },
    settingSection: {
      paddingRight: '20px',
      '& > span': {
        padding: '0 10px',
      },
    },
    settingSectionMobile: {
      paddingRight: '30px',
      '& > span': {
        padding: '0 10px',
      },
    },
    settingIcon: {
      marginBottom: '0 !important',
      marginRight: '13px',
    },
  })
);

const MainParticipantContainer = styled('div')(({ theme }) => ({
  //   position: 'absolute',
  //   left: theme.sidebarWidth,
  //   right: 0,
  //   top: 0,
  //   bottom: 0,
  //   '& > div': {
  //     height: '100%',
  //   },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

//   const StyledTabs = withStyles({
//     root: {
//         borderBottom: '2px solid #b5b5b5',
//         '& > div > div': {
//             justifyContent: 'space-between',
//         }
//     },
//     indicator: {
//         display: 'flex',
//         // color: '#79b928',
//         justifyContent: 'center',
//         backgroundColor: 'transparent',
//         '& > div': {
//             width: '100%',
//             // backgroundColor: '#79b928',
//         },
//     },
//   })((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

// const StyledTab = withStyles((theme) => ({
//     root : {
//         textTransform: 'capitalize',
//         height: '47px',
//         paddingTop: '0',
//         paddingBottom: '0',
//         minHeight: 'auto',
//         fontSize: '15px',
//         '& > span': {
//             flexDirection: 'row',
//         },
//         '&>span:nth-child(1)': {
//             filter: 'invert(44%) sepia(0%) saturate(0%) hue-rotate(7deg) brightness(98%) contrast(91%)'
//         }
//     },
//     selected: {
//         '&>span:nth-child(1)': {
//             borderBottom: '2px solid #79b928',
//             height: '47px',
//             color: '#79b928',
//             flexDirection: 'row',
//             filter: 'invert(30%) sepia(79%) saturate(421%) hue-rotate(44deg) brightness(104%) contrast(88%)'
//         }
//     },
// }))(Tab);

var formatted_messages: any = [];
var tab_state: any = 'chat';
var drawer_state: any = false;
var new_message_count = 0;

export default function Room({handleSidebarStatus}) {
  const modules = {
    toolbar: [['']],
    magicUrl: {
      urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(mailto:[\S]+)|(tel:[\S]+)/,
      globalRegularExpression: /(https?:\/\/|www\.|mailto:|tel:)[\S]+/g,
    },
  };

  const classes = useStyles();
  const {
    room: { localParticipant },
    chatChannel,
  } = useVideoContext();

  const [value, setValue] = React.useState(tab_state);

  const [open, setOpen] = React.useState(drawer_state);
  const [messageInput, setMessageInput] = useState<any>('');
  const [newMessage, setNewMessage] = React.useState(false);
  const [newMessageText, setNewMessageText] = React.useState('');
  const [messages, setMessages] = useState<any>(formatted_messages);
  const [key, setKey] = useState<any>('chat');
  const isMobile = useResponsiveQuery();

  useEffect(() => {
    new_message_count = 0;
  }, []);

  const handleDrawerOpen = () => {
    drawer_state = true;
    setOpen(true);
    if (tab_state === 'chat') {
      new_message_count = 0;
      setNewMessage(false);
    }
    handleSidebarStatus(open)
  };

  const handleDrawerClose = () => {
    drawer_state = false;
    setOpen(false);
    if (tab_state == 'chat') {
      new_message_count = 0;
      setNewMessage(false);
    }
    handleSidebarStatus(open)
  };
  const participants = useParticipants();

  const total_participant = participants.length + 1;

  const getDefaultMessages = () => {
    if (chatChannel) {
      chatChannel.getMessages().then(messagePage => {
        formatted_messages = messagePage.items.map(formatMessages);
        setMessages(formatted_messages);
      });
    }
  };
  useEffect(() => {
    getDefaultMessages();
    if (chatChannel) {
      chatChannel.removeListener('messageAdded', messageAdded);
      chatChannel.on('messageAdded', messageAdded);
    }
  }, [chatChannel]);

  const messageAdded = message => {
    let newMsgObj = formatMessages(message);
    let newMsgText = `${newMsgObj.author.name}: ${newMsgObj.text}`;
    setNewMessageText(newMsgText);
    formatted_messages = formatted_messages.concat(newMsgObj);
    setMessages(formatted_messages);
    if ((drawer_state && tab_state == 'people') || !drawer_state) {
      new_message_count++;
      setNewMessage(true);
    }
    if (document.getElementsByClassName('message-list').length > 0) {
      document.getElementsByClassName('message-list')[0].scrollTop = document.getElementsByClassName(
        'message-list'
      )[0].scrollHeight;
    }
  };

  const formatMessages = message => {
    return {
      text: message.body,
      sid: message.sid,
      author: { id: message.author, name: message.author },
      timestamp: timeFormat(message.dateCreated),
    };
  };

  const timeFormat = date => {
    var result = '';
    var hours = date.getHours();
    var minutes = date.getMinutes();
    minutes = (minutes < 10 ? '0' : '') + minutes;
    var amORpm = hours > 12 ? 'PM' : 'AM';
    if (hours > 12) hours -= 12;
    return hours + ':' + minutes + ' ' + amORpm;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (messageInput.replace(/(<([^>]+)>)/gi, '').trim() !== '') {
      chatChannel.sendMessage(messageInput);
      setMessageInput('');
    }
  };

  const handleSubmitBtn = () => {
    if (messageInput.replace(/(<([^>]+)>)/gi, '').trim() !== '') {
      chatChannel.sendMessage(messageInput);
      setMessageInput('');
    }
  };

  const handleNameChange = event => {
    setMessageInput(event.target.value);
  };

  const handleChange = (eventKey: any, event: React.SyntheticEvent<any>) => {
    tab_state = eventKey;
    setKey(eventKey);
    if (tab_state === 'chat') {
      new_message_count = 0;
      setNewMessage(false);
    }
  };

  const handleNewMsgClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setNewMessageText('');
    setNewMessage(false);
  };

  return (
    <Grid container direction="row">
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
          [classes.contentShiftMobile]: isMobile && open,
        })}
      >
        <MainParticipantContainer
          className={clsx({
            [classes.mainSpeakerContainerMobile]: isMobile,
            [classes.mainSpeakerContainer]: !isMobile,
          })}
        >
          <MainParticipant />

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide, classes.openDrawerIcon, { [classes.openDrawerIconMobile]: isMobile })}
          >
            <MenuIcon />

            {/* <div className="newMsgBadge"><span></span></div> */}
          </IconButton>

          <IconButton
            onClick={handleDrawerClose}
            className={clsx(!open && classes.hide, classes.closeDrawerIcon, {
              [classes.closeDrawerIconMobile]: isMobile,
            })}
          >
            <MenuIcon />

            {/* <div className="newMsgBadge"><span></span></div> */}
          </IconButton>
        </MainParticipantContainer>
      </main>

      {isMobile && (
        <MainParticipantContainer className={classes.localSpeakerContainer}>
          <MainParticipantInfo participant={localParticipant} isLocalParticipant={true} isSingleParticipant={false}>
            <ParticipantTracks participant={localParticipant} disableAudio isMainSpeaker={false} />
          </MainParticipantInfo>
        </MainParticipantContainer>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={newMessage}
        autoHideDuration={6000}
        onClose={handleNewMsgClose}
        message={<div dangerouslySetInnerHTML={{ __html: newMessageText }} />}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleNewMsgClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment> 
        }
      />
      {
        !isMobile &&

        <div className="participants-wrapper">
          <RSC>
           <ParticipantStrip />
          </RSC>
        </div>
      }
        
      <div>
        <Drawer
          variant="persistent"
          anchor="right"
          open={open}
          className="drawer-paper-sidebar"
          classes={{ paper: !isMobile ? classes.drawerPaper : classes.drawerPaperMobile }}
        >
          <Tabs activeKey={key} onSelect={handleChange} className="drawer-tabs-container">
           {isMobile && <Tab
              eventKey="people"
              title={
                <span>
                  <PeopleIcon /> {!isMobile ? 'People' : `(${total_participant})`}
                </span>
              }
            >
             <ParticipantStrip />
            </Tab>
            }
            <Tab
              disabled={!chatChannel}
              eventKey="chat"
              title={
                <span>
                  {/* <img src={require('../../assets/images/chat-new.svg')} /> */}

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M4 4h16v12H5.17L4 17.17V4m0-2c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4zm2 10h8v2H6v-2zm0-3h12v2H6V9zm0-3h12v2H6V6z" />
                  </svg>
                  {!isMobile ? `Chat (${new_message_count})` : `(${new_message_count})`}
                </span>
              }
            >
              <ChatWindow messages={messages} localParticipant={localParticipant}/>

              <form autoComplete="off" className="chat-form" onSubmit={handleSubmit}>
                {/* <TextField
                  id="menu-name"
                  value={messageInput}
                  onChange={handleNameChange}
                  variant="outlined"
                  margin="dense"
                /> */}
                <ReactQuill
                  id="menu-name"
                  theme="snow"
                  modules={modules}
                  value={messageInput}
                  onChange={setMessageInput}
                />
                <Button onClick={handleSubmitBtn} className="send-btn">
                  {/* <img src={require('../../assets/images/send_btn.png')} alt="no video" /> */}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M4.01 6.03l7.51 3.22-7.52-1 .01-2.22m7.5 8.72L4 17.97v-2.22l7.51-1M2.01 3L2 10l15 2-15 2 .01 7L23 12 2.01 3z" />
                  </svg>
                </Button>
              </form>
            </Tab>
          </Tabs>
        </Drawer>
      </div>
    </Grid>
  );
}
