import React, { ChangeEvent, useState, useEffect, useCallback } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';
import Select from '@material-ui/core/Select';
import { useAppState } from '../../state';
import MenuItem from '@material-ui/core/MenuItem';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Video from 'twilio-video';

const StyledSelect = withStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    alignItems: 'center',
    paddingRight: '32px',
    paddingLeft: '14px',
    padding: '9px 32px 10px 9px',
    lineHeight: '1',
  },
}))(Select);

interface Provider {
  deviceId: string;
  label: string;
}

// interface IState {
//     menuitem?: Array<Provider>;
//     optionSelected?: unknown;
// }

export function MediaOptions(props: any) {
  const { URLRoomName } = useParams();
  const [menuitem, setMenuItem] = useState();
  const [optionSelected, setOption] = useState();
  const { user, getToken, isFetching } = useAppState();
  const [roomName, setRoomName] = useState<string>('');
  const roomState = useRoomState();
  const [name, setName] = useState<string>(user?.displayName || '');
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
    setLocalVideoTrack,
    setLocalAudioTrack,
  } = useVideoContext();

  const videoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack;
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;

  //to maitain the state of selected video device in settings form
  useEffect(() => {
    if (videoTrack && props.name == 'videoinput' && menuitem) {
      const selectedTrackLabel = videoTrack ? videoTrack.mediaStreamTrack.label : '';
      const frontCamera = menuitem.find(item => item.label.includes('front'));
      const backCamera = menuitem.find(item => item.label.includes('back'));
      if (selectedTrackLabel.includes('front') && frontCamera) {
        setOption(frontCamera.deviceId);
        setLocalVideoTrack(videoTrack, frontCamera.deviceId);
      } else if (selectedTrackLabel.includes('back') && backCamera) {
        setOption(backCamera.deviceId);
        setLocalVideoTrack(videoTrack, backCamera.deviceId);
      }
    }
  }, [localTracks, menuitem]);

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
  }, [URLRoomName]);

  useEffect(() => {
    if (props.username) {
      console.log('set nameeee' + roomState);
      setName(props.username);
    }
  }, [props.username]);

  const handleMediaChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const ele = event.nativeEvent.target as HTMLElement;
    const label_name = ele.innerText;
    if (roomState !== 'disconnected') {
      console.log(Array.from(localParticipant.videoTracks.values()));
    }
    console.log(localTracks);
    // const name = event.target.name as keyof typeof IState;
    const deviceId: any = event.target.value;
    setOption(deviceId);

    if (props.name == 'videoinput') {
      Video.createLocalVideoTrack({
        frameRate: 24,
        height: 720,
        width: 1280,
        // name: label_name,
        deviceId: deviceId,
      })
        .then((changedTrack: any) => {
          //   changedTrack = localTracks.find(device => device.kind === 'video');
          // setLocalVideoTrack(changedTrack).then((track) => {
          console.log('local   track changed');
          if (roomState !== 'disconnected') {
            localParticipant.videoTracks.forEach(function(trackPublication) {
              trackPublication.track.stop();
              const localTrackPublication = localParticipant.unpublishTrack(trackPublication.track);
              localParticipant.emit('trackUnpublished', localTrackPublication);
            });
            localParticipant.publishTrack(changedTrack);
          } else {
            changedTrack.attach(document.getElementById('videotrackoutput'));
          }
          setLocalVideoTrack(changedTrack, deviceId);
          // });
        })
        .catch(function(error) {
          console.log('applyVideoOutputDeviceSelection failed:', error);
        });
    } else if (props.name == 'audioinput') {
      console.log(deviceId);
      Video.createLocalAudioTrack({
        name: label_name,
        deviceId: {
          exact: deviceId, // NOTE: on ios safari - it respects the deviceId only if its exact.
        },
      })
        .then(function(localTrack) {
          console.log(localTrack);
          console.log('local  audio track changed');
          if (roomState !== 'disconnected') {
            localParticipant.audioTracks.forEach(function(trackPublication) {
              trackPublication.track.stop();
              const localTrackPublication = localParticipant.unpublishTrack(trackPublication.track);
              localParticipant.emit('trackUnpublished', localTrackPublication);
            });
            localParticipant.publishTrack(localTrack);
          }
          setLocalAudioTrack(localTrack);
          localTrack.enable();
        })
        .catch(function(error) {
          console.log('applyAudioInputDeviceSelection failed:', error);
        });
    } else if (props.name == 'audiooutput') {
      const audioElement = audioTrack.attach();
      console.log(audioElement);
      document.body.appendChild(audioElement);
    }
  };

  const getOptions = useCallback(() => {
    console.log(localTracks);
    const medianame = props.name;
    const menuitemtemp: Array<Provider> = [];

    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(function() {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        devices.forEach(function(device) {
          if (device.kind == medianame) {
            var deviceId = device.deviceId;
            var label = device.label || 'Device [ id: ' + deviceId.substr(0, 5) + '... ]';

            menuitemtemp.push({ label: label, deviceId: deviceId });
          }
        });
        setMenuItem(menuitemtemp);
      });
    });
  }, []);

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <StyledSelect
      // style={classes.mediaOptionsSelect}
      variant="outlined"
      value={optionSelected ? optionSelected : menuitem && menuitem[0] ? menuitem[0].deviceId : ''}
      onChange={e => handleMediaChange(e)}
      className="width50"
    >
      {menuitem &&
        menuitem.map(item => (
          <MenuItem key={item.label} value={item.deviceId}>
            {item.label}
          </MenuItem>
        ))}
    </StyledSelect>
  );
}
