import React from 'react';

export function ChatWindow(props: any) {
  setTimeout(() => {
    if (document.getElementsByClassName('message-list').length > 0) {
      document.getElementsByClassName('message-list')[0].scrollTop = document.getElementsByClassName(
        'message-list'
      )[0].scrollHeight;
    }
  }, 200);
 


  return (
    <div className="message-list">
      <ul>
        {props.messages.map(message => (
          
          <li key={message.sid}   className={props.localParticipant.identity === message.author.name ? 'self-message' : ''}>
            <div className="msg-info">
              <span className="msg-auth">{message.author.name.substring(0, 1)}</span>
              <div className="message-item">
                <div className="msg-text" dangerouslySetInnerHTML={{ __html: message.text }}></div>

                <div className="msg-bottom">
                  <span className="msg-username-full">{message.author.name}</span>
                  <span className="msg-time">{message.timestamp}</span>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
