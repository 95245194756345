import React from 'react';
import clsx from 'clsx';
import { Pin } from '@primer/octicons-react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SvgIcon from '@material-ui/core/SvgIcon';
import useResponsiveQuery from '../../../hooks/useResponsiveQuery/useResponsiveQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    svgStyle: {
      stroke: 'inherit !important',
      height: '17px',
      width: '17px',
    },
    pinned: {
      fill: '#4CAF50',
    },
    notPinned: {
      fill: '#6E6E6E',
    },
    svgStyleMobile: {
      height: '17px',
      width: '17px',
    },
  })
);

interface PinProps {
  isSelected: boolean;
}

export default function PinIcon({ isSelected }: PinProps) {
  const classes = useStyles();
  const isMobile = useResponsiveQuery();
  console.log(isSelected);

  return (
    <Tooltip title="" placement="top">
      <svg
        className={clsx(classes.svgStyle, { [classes.svgStyleMobile]: isMobile })}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27 27"
      >
        <path
          className={clsx({ [classes.notPinned]: !isSelected, [classes.pinned]: isSelected })}
          d="M26.306 5.935L21.066.694c-.926-.925-2.431-.925-3.357 0l-1.124 1.125c-.149.148-.232.35-.232.559 0 .21.083.411.232.56l.53.53-6.356 5.67c-1.313-.765-3.029-.587-4.152.537l-1.678 1.677c-.309.309-.309.81 0 1.119l2.718 2.717-7.5 10.563c-.224.315-.188.744.085 1.017.153.153.355.232.56.232.159 0 .319-.048.457-.146l10.563-7.501 2.717 2.718c.149.148.35.231.56.231.21 0 .41-.083.559-.231l1.677-1.678c1.124-1.123 1.302-2.84.536-4.152l5.67-6.357.532.531c.148.149.35.232.559.232.21 0 .411-.083.56-.232l1.124-1.124c.925-.926.925-2.431 0-3.356zM4.138 22.862l4.644-6.539 1.895 1.895-6.539 4.644zm12.068-3.587l-1.117 1.118-8.482-8.482 1.118-1.117c.349-.349.812-.541 1.305-.541.493 0 .956.192 1.305.54l5.872 5.873c.348.348.54.811.54 1.304s-.192.956-.54 1.305zm.61-4.238l-4.853-4.853 6.273-5.595 4.175 4.175-5.595 6.273zm8.371-6.865l-.565.565-6.36-6.359.566-.565c.309-.309.81-.309 1.119 0l5.24 5.24c.309.309.309.81 0 1.119z"
        />
      </svg>
    </Tooltip>
  );
}
