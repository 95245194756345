import React from 'react';
import clsx from 'clsx';
import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useResponsiveQuery from '../../hooks/useResponsiveQuery/useResponsiveQuery';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noPhoto: {
      width: '119px',
      height: '119px',
      backgroundColor: '#777777',
      borderRadius: '100%',
      lineHeight: 'normal',
      letterSpacing: '1.31px',
      textAlign: 'center',
      color: '#ffffff',
      fontSize: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    noPhotoMainWrapper: {
      background: 'unset',
      display: 'flex',
      width: '482px',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    noPhotoMainWrapperMobile: {
      width: '100%',
    },
    noVideoMainWrapper: {
      height: '100vh',
    },
  })
);

const Container = styled('div')({
  width: '100%',
  objectFit: 'contain',
  backgroundColor: '#000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
  fontSize: '20px',
});

export default function MainParticipant() {
  const classes = useStyles();
  const mainParticipant = useMainSpeaker();
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  const videoPriority =
    mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant ? 'high' : null;

  const participants = useParticipants();
  const isMobile = useResponsiveQuery();
  console.log(participants);

  if (isMobile && participants.length == 0) {
    return (
      /* audio is disabled for this participant component because this participant's audio 
               is already being rendered in the <ParticipantStrip /> component.  */
      <MainParticipantInfo participant={mainParticipant} isLocalParticipant={false} isSingleParticipant={true}>
        <Container className={classes.noVideoMainWrapper}>
          <div className={clsx({ [classes.noPhotoMainWrapper]: true, [classes.noPhotoMainWrapperMobile]: isMobile })}>
            <img className={classes.noPhoto} src={require('../../assets/images/no-video.png')} alt="no video" />
          </div>
        </Container>

        {/* <ParticipantTracks participant={mainParticipant} disableAudio enableScreenShare videoPriority={videoPriority} isMainSpeaker={true}/> */}
      </MainParticipantInfo>
    );
  } else {
    return (
      /* audio is disabled for this participant component because this participant's audio 
               is already being rendered in the <ParticipantStrip /> component.  */
      <MainParticipantInfo participant={mainParticipant} isLocalParticipant={false} isSingleParticipant={false}>
        <ParticipantTracks
          participant={mainParticipant}
          disableAudio
          enableScreenShare
          videoPriority={videoPriority}
          isMainSpeaker={true}
        />
      </MainParticipantInfo>
    );
  }
}
